<template>
    <page-section id="section">
        <page-section-title title="Tutti開発の強み"></page-section-title>
        
        <div :class="$vuetify.breakpoint.forPC ? 'd-flex justify-space-between' : ''">
            <strength-card
                title="データラベリングの専門的な知見"
                body="主にクラウドソーシングを用いた数千人規模のデータラベル収集や
                      データ分析に多数の実績があります。ラベリングシステムの設計や
                      運用に関する幅広い知見を強みに、製品開発やお客様サポートを行っています。"
                :fig-src="require('@/assets/web-figures/strength-01.png')"
            >
            </strength-card>
            <strength-card
                title="早稲田大学との共同研究"
                body="Tuttiを開発する株式会社知能フレームワーク研究所（ifLab Inc.）は、
                      早稲田大学知覚情報システム研究室から始まった大学発スタートアップ企業です。
                      AIにおける共同研究でのツール活用・研究成果によるツール強化のサイクルを回し、開発を進めています。"
                :fig-src="require('@/assets/web-figures/strength-02.png')"
            >
            </strength-card>
        </div>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from './PageSectionTitle.vue'
import StrengthCard from './StrengthCard.vue'
export default {
  components: {
    PageSection,
    PageSectionTitle,
    StrengthCard
  },
}
</script>
<style scoped>
#section {
    background-image: url('~@/assets/web-figures/background-grid.png');
    background-repeat: repeat;
    font-size: 16px;
}
</style>
