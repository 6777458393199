<template>
    <hr id="divider" :style="styles.divider" />
</template>
<script>
export default {
    computed: {
        styles() {
            return {
                divider: {
                    width: this.$vuetify.breakpoint.forPC ? '115px' : '100px',
                    margin: this.$vuetify.breakpoint.forPC ? '30px auto 50px auto' : '10px auto 30px auto'
                },
            }
        }
    }
}
</script>
<style scoped>
#divider {
    height: 5px;
    background-color: #FCE263;
    border: none;
}
</style>