<template>
    <v-col :cols="$vuetify.breakpoint.forPC ? false : 12" class="content" :style="styles.content">
        <img id="num" :src="numFigSrc" :style="styles.num" />
        <p id="title" :style="styles.title" v-html="title"></p>
        <p id="body" :style="styles.body" v-html="body"></p>
        <img id="figure" :src="figSrc" />
    </v-col>
</template>
<script>
export default {
    props: {
        index: { type: [Number, String], default: 1 },
        title: { type: String, default: '' },
        body: { type: String, default: '' },
        figSrc: { type: String, default: null }
    },
    computed: {
        styles() {
            return {
              content: {
                  maxWidth: this.$vuetify.breakpoint.forPC ? '300px' : null,
              },
              num: {
                  width: this.$vuetify.breakpoint.forPC ? '40%' : '100px',
              },
              title: {
                  paddingTop: this.$vuetify.breakpoint.forPC ? '50px' : '20px',
              },
              body: {
                  fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px',
              }
            }
        },
        numFigSrc() {
            return require(`@/assets/web-figures/advantage-num-0${this.index}.png`);
        }
    }
}
</script>
<style scoped>
.content {
    position: relative;
}
#num {
    position: absolute;
    top: 0;
    left: 0;
}
#title {
    font-size: 18px;
    position: relative;
    text-align: center;
    font-weight: 700;
}
#body {
    width: 100%;
    position: relative;
}
#figure {
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    display: block;
    margin: 0 auto;
}
</style>