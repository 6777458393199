<template>
    <v-card class="pa-2">
        <v-card-title>
            <div id="title" :style="styles.title" v-html="title"></div>
        </v-card-title>
        <v-card-text id="body" :style="styles.body">
            <div v-html="body"></div>
            <div id="profile" class="d-flex">
                <div id="avatar">
                    <v-avatar :size="$vuetify.breakpoint.forPC ? 100 : 70">
                        <img :src="avatarSrc" />
                    </v-avatar>
                </div>
                <div id="affiliation" :style="styles.affiliation">
                    <div v-if="logoSrc" id="logo" :style="styles.logo">
                        <img :src="logoSrc" />
                    </div>
                    <div v-html="affiliation"></div>
                    <div id="name" v-html="name"></div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        title: { type: String, default: '' },
        body: { type: String, default: '' },
        avatarSrc: { type: String, default: '' },
        logoSrc: { type: String, default: null },
        affiliation: { type: String, default: '' },
        name: { type: String, default: '' },
    },
    computed: {
        styles() {
            return {
                title: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '24px' : '18px'
                },
                body: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
                },
                logo: {
                    height: this.$vuetify.breakpoint.forPC ? '40px' : '30px'
                },
                logoImg: {
                    height: this.$vuetify.breakpoint.forPC ? '100px' : '70px'
                },
                affiliation: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? '20px' : '10px'
                }
            }
        }
    }
}
</script>
<style scoped>
#title {
    padding-left: 20px;
    border-left: 5px solid #FFE443;
    font-weight: 700;
}
#body {
    color: black;
}
#profile {
    padding-top: 30px;
}
#avatar {
    padding-right: 30px;
}
#logo {
    margin-bottom: 10px;
}
#logo > img {
    height: 100%;
}
</style>
