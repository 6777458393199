<template>
    <page-section class="grey lighten-4">
        <page-section-title
            title="Tuttiの特徴"
        ></page-section-title>

        <feature-boxes
            :index="0"
            title="迅速なラベリングシステム開発"
            body="様々なラベリングタスクに対応した豊富なページテンプレートからラベリング画面の設計を始めることで、
                  ラベリングシステム開発の工数を大きく削減できます。<br>
                  HTML、CSS、JavaScriptのソースコード単位で開発を進めることもできるので、
                  カスタム仕様のラベリング画面の作成も思いのまま。"
            :fig-src="require('@/assets/web-figures/feature-figure-01.png')"
        ></feature-boxes>
        <feature-boxes
            :index="1"
            title="高機能なラベリング作業フロー設計"
            body="最初にラベリングの練習画面を表示したり、定期的に作業者の
                  パフォーマンスをチェックするための質問を挟んだりなど、
                  ラベリング作業の流れを自由に決めることができます。<br>
                  フローチャート設計機能により、複数の画面のページ遷移を伴う
                  高機能なラベリング作業を、一つの画面の中で実現できます。"
            :fig-src="require('@/assets/web-figures/feature-figure-02.png')"
        ></feature-boxes>
        <feature-boxes
            :index="2"
            title="作業プラットフォーム連携機能で<br>簡単人海戦術"
            body="Tuttiで開発したラベリングシステムは、管理画面での簡単な操作のみで、
                  作業者の集まる各種プラットフォームへすぐに配信できます。<br>
                  自社社員のラベリング人材が登録されたポータルサイトや、何万人ものワーカーが
                  集まるクラウドソーシングサイトへの外注に、特別な知識は一つも要りません。"
            :fig-src="require('@/assets/web-figures/feature-figure-03.png')"
        ></feature-boxes>
        <feature-boxes
            :index="3"
            title="スムーズなデータ分析・活用を<br>実現するAPI"
            body="Tuttiのデータ領域に蓄積されたラベリング対象データやラベリング結果は、
                  PythonやJavaScriptなどのAPIを介して取得可能です。<br>
                  ラベリング対象データを自動で追加したり、作業者の回答をプッシュ通知で受け取ったりなど、
                  独自のアプリケーションとの相性も抜群です。"
            :fig-src="require('@/assets/web-figures/feature-figure-04.png')"
        ></feature-boxes>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'
import FeatureBoxes from '../components/FeatureBoxes.vue'

export default {
  components: { PageSection, PageSectionTitle, FeatureBoxes },
}
</script>,
