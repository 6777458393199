<template>
    <page-section :style="styles.wrapper">
        <page-section-title
            title="Tuttiでできること"
            subtitle="非効率なラベリング作業で、ビジネスチャンスを逃していませんか？"
        ></page-section-title>
        <v-row class="justify-space-between">
            <advantage-column index="1"
                title="データラベリング<br>環境構築の工数削減"
                body="全てウェブベースなので環境構築は不要。管理画面上でどんなラベリングシステムもあっという間に作り、すぐ運用を開始できます。"
                :figSrc="$vuetify.breakpoint.forPC ? null : getFigureSrcByIndex(1)"
            ></advantage-column>
            <advantage-column index="2"
                title="複数人での<br>迅速なデータセット構築"
                body="ラベリングをストレスフリーに手分けしましょう。次にラベリングすべきデータをTuttiが自動的に判断し、各作業者に提示します。"
                :figSrc="$vuetify.breakpoint.forPC ? null : getFigureSrcByIndex(2)"
            ></advantage-column>
            <advantage-column index="3"
                title="ラベリング作業を<br>業務フローへ円滑に組み込む"
                body="作業者コミュニティや自作アプリケーションとのデータ連携機能により、ラベリング業務を組織内の運用フローへ柔軟に組み込めます。"
                :figSrc="$vuetify.breakpoint.forPC ? null : getFigureSrcByIndex(3)"
            ></advantage-column>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.forPC" class="justify-space-between">
            <v-col class="content" :style="styles.content">
                <img class="advantage-content-figure" :src="getFigureSrcByIndex(1)" />
            </v-col>
            <v-col class="content" :style="styles.content">
                <img class="advantage-content-figure" :src="getFigureSrcByIndex(2)" />
            </v-col>
            <v-col class="content" :style="styles.content">
                <img class="advantage-content-figure" :src="getFigureSrcByIndex(3)" />
            </v-col>
        </v-row>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'
import AdvantageColumn from '@/components/AdvantageColumn'

export default {
    components: { PageSection, PageSectionTitle, AdvantageColumn },
    computed: {
        styles() {
            return {
                wrapper: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? undefined : '100px',
                },
                content: {
                    maxWidth: this.$vuetify.breakpoint.forPC ? '300px' : null,
                },
                num: {
                    width: this.$vuetify.breakpoint.forPC ? '40%' : '100px',
                },
                title: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? '50px' : '20px',
                },
            }
        }
    },
    methods: {
        getFigureSrcByIndex(index) {
            return require(`@/assets/web-figures/advantage-figure-0${index}.png`);
        }
    }
}
</script>,
<style scoped>
img.advantage-content-figure {
    display: block;
    margin: 0 auto;
}
</style>
