<template>
    <v-row :style="styles.wrapper" :class="flexDirection" align="center">
        <v-col :cols="$vuetify.breakpoint.forPC ? 6 : 12" :class="bodyColumnPadding">
            <p id="title" :style="styles.title" v-html="title" />
            <p :style="styles.body" v-html="body" />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.forPC ? 6 : 12" :class="['text-center', figureColumnPadding]">
            <img :src="figSrc" :style="styles.figure" />
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        index: { type: Number, default: 0 },
        title: { type: String, default: '' },
        body: { type: String, default: '' },
        figSrc: { type: String, default: '' }
    },
    computed: {
        styles() {
            return {
                wrapper: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? '70px' : '20px',
                },
                title: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '24px' : '18px',
                },
                body: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px',
                },
                figure: {
                    maxHeight: this.$vuetify.breakpoint.forPC ? undefined : '200px',
                    maxWidth: this.$vuetify.breakpoint.forPC ? undefined : '90%',
                },
            }
        },
        flexDirection() {
            return this.index%2==0 ? 'flex-row' : 'flex-row-reverse';
        },
        bodyColumnPadding() {
            return this.$vuetify.breakpoint.forPC ? (this.index%2==0 ? 'pr-12' : 'pl-12') : '';
        },
        figureColumnPadding() {
            return this.$vuetify.breakpoint.forPC ? (this.index%2==0 ? 'pl-12' : 'pr-12') : '';
        }
    }
}
</script>
<style scoped>
#title {
    font-weight: 700;
}
</style>