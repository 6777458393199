<template>
    <div id="wrapper" class="text-center yellow darken-1" :style="styles.wrapper">
        <p id="text" :style="styles.text">
            Tuttiについて<br v-if="!$vuetify.breakpoint.forPC">詳しく知りたい方はこちら
        </p>
        <contact-button :style="styles.button"></contact-button>
    </div>
</template>
<script>
import ContactButton from './ContactButton.vue'
export default {
    components: { ContactButton },
    computed: {
        styles() {
            return {
                wrapper: {
                    padding: this.$vuetify.breakpoint.forPC ? '45px 0' : '25px 0'
                },
                text: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '24px' : '16px'
                },
                button: {
                    marginTop: this.$vuetify.breakpoint.forPC ? '45px' : '15px'
                },
            }
        }
    }
}
</script>
<style scoped>
#wrapper {
    padding: 45px 0;
    box-shadow: 0 3px 3px 0px rgb(198 198 198 / 75%);
}
#text {
    margin: 0;
    font-weight: 700;
}
</style>
