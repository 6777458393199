<template>
    <page-section>
        <page-section-title title="導入機関"></page-section-title>
        <!--<div class="d-flex justify-center flex-wrap">-->
        <div>
            <div
                id="logo-wrapper"
                v-for="logo, i in logos"
                :key="`customer-logo-${i}`"
            >
                <!--<img class="logo" :style="styles.logo" :src="src" />-->
                <p v-if="logo.type==='text'" class="logo-text">{{ logo.src }}</p>
                <img v-if="logo.type==='img'" class="logo" :style="styles.logo" :src="logo.src" />
            </div>
        </div>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'

export default {
    components: { PageSection, PageSectionTitle },
    data: () => ({
        logos: [
            { type: 'img', src: require('@/assets/images/customers/logos/equmenopolis.png') },
            { type: 'text', src: '筑波大学' },
            { type: 'text', src: '量子化学技術研究開発機構（QST）' },
            { type: 'img', src: require('@/assets/images/customers/logos/waseda.png') },
        ],
    }),
    computed: {
        styles() {
            return {
                logo: {
                    maxWidth: this.$vuetify.breakpoint.forPC ? '250px' : '200px'
                },
            }
        }
    }
}
</script>
<style scoped>
#logo-wrapper {
    text-align: center;
    padding: 20px;
}
img.logo {
    width: 100%;
}
.logo-text {
    font-size: 24px;
}
</style>
