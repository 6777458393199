<template>
    <v-card class="my-4 pa-2">
        <div id="header" class="pa-4 pb-0 d-flex">
            <div id="date" :style="styles.date" v-html="date"></div>
            <div id="tag" :style="styles.tag" v-text="tagText"></div>
        </div>

        <v-card-title>
            <div id="title" :style="styles.title" class="font-weight-bold" v-html="title"></div>
        </v-card-title>

        <v-card-text id="body" :style="styles.body" v-html="body"></v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        date: { type: String, default: '' },
        tag: { type: String, default: '' },
        title: { type: String, default: '' },
        body: { type: String, default: '' },
    },
    computed: {
        tagText() {
            let text;
            if(this.tag === 'press')
                text = 'プレスリリース';
            else if(this.tag === 'product')
                text = '製品情報';
            else if(this.tag === 'event')
                text = 'イベント情報';
            return text;
        },
        styles() {
            return {
                date: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
                },
                tag: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
                },
                title: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '20px' : '16px'
                },
                body: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
                },
            }
        }
    }
}
</script>
<style scoped>
#date {
    width: 100px;
    color: #777;
}
#tag {
    width: 150px;
    background-color: #FFE443;
    text-align: center;
    font-weight: 700;
}
#profile {
    padding-top: 30px;
}
#avatar {
    padding-right: 30px;
}
#logo {
    height: 40px;
    margin-bottom: 10px;
}
#logo > img {
    height: 100%;
}
#name::after {
    content: '様';
}
</style>
