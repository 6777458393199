<template>
    <v-sheet>
        <top-first-view-section></top-first-view-section>
        <advantages-section></advantages-section>
        <call-to-action-section></call-to-action-section>
        <features-section></features-section>
        <strength-section></strength-section>
        <customer-voices-section></customer-voices-section>
        <customers-section></customers-section>
        <news-section></news-section>
        <link-anchor id="contact"></link-anchor>
        <contact-section></contact-section>
        <footer-section></footer-section>
    </v-sheet>
</template>
<script>
import TopFirstViewSection from '../components/TopFirstViewSection.vue'
import AdvantagesSection from '../components/AdvantagesSection.vue'
import FeaturesSection from '../components/FeaturesSection.vue'
import CallToActionSection from '../components/CallToActionSection.vue'
import StrengthSection from '../components/StrengthSection.vue'
import CustomerVoicesSection from '../components/CustomerVoicesSection.vue'
import CustomersSection from '../components/CustomersSection.vue'
import NewsSection from '../components/NewsSection.vue'
import ContactSection from '../components/ContactSection.vue'
import FooterSection from '../components/FooterSection.vue'
import LinkAnchor from '../components/LinkAnchor.vue'


export default {
    components: {
        TopFirstViewSection,
        AdvantagesSection,
        FeaturesSection,
        CallToActionSection,
        StrengthSection,
        CustomerVoicesSection,
        CustomersSection,
        NewsSection,
        ContactSection,
        FooterSection,
        LinkAnchor
    },
    mounted() {
        if(this.$route.hash !== '') {
            window.location.href = this.$route.hash;
        }
    }
}
</script>
