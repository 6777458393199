<template>
    <v-card :style="styles.card" class="pa-8">
        <p id="title" class="text-center" v-html="title"></p>
        <p :style="styles.body" v-html="body"></p>
        <div class="text-center">
            <img :src="figSrc" />
        </div>
    </v-card>
</template>
<script>
export default {
    props: {
        title: { type: String, default: '' },
        body: { type: String, default: '' },
        figSrc: { type: String, default: '' },
    },
    computed: {
        styles() {
            return {
                card: {
                    width: this.$vuetify.breakpoint.forPC ? '450px' : '100%',
                    margin: this.$vuetify.breakpoint.forPC ? '' : '30px auto'
                },
                body: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
                }
            }
        }
    }
}
</script>
<style scoped>
#title {
    font-size: 18px;
    font-weight: 700;
}
</style>