<template>
    <div id="wrapper" :style="styles.wrapper">
        <p class="mb-0 font-weight-bold text-center" :style="styles.title" v-html="title" />
        <p class="mb-0 subtitle text-center" :style="styles.subtitle" v-html="subtitle" />
        <divider />
    </div>
</template>
<script>
import Divider from '@/components/Divider'
export default {
    components: { Divider },
    props: {
        title: { type: String, default: '' },
        subtitle: { type: String, default: '' }
    },
    computed: {
        styles() {
            return {
                wrapper: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? '0' : '20px'
                },
                title: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '28px' : '20px'
                },
                subtitle: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '18px' : '14px'
                },
            }
        }
    }
}
</script>